"use strict";
import swiperNumber from "./modules/swiperNumber";
import swiperWho from "./modules/swiperWho";
import swiperPricing from "./modules/swiperPricing";
import swiperIncluded from "./modules/swiperIncluded";
import pricingGenerator from "./modules/pricingGenerator";
import mouseHandler from "./modules/mouseHandler";
import firstAnimations from "./modules/firstBlocksAnimation";
import secondAnimations from "./modules/secondBlocksAnimation";
import stepBlocks from "./modules/stepBlocks";
import feautersBlock from "./modules/showFeauters";
import showAboutVeronica from "./modules/showAboutVeronica";
import showFeedback from "./modules/showFeedback";

window.addEventListener("DOMContentLoaded", () => {
  gsap.registerPlugin(ScrollToPlugin);
  gsap.registerPlugin(ScrollTrigger);

  swiperNumber();
  swiperWho();
  swiperPricing();
  swiperIncluded();
  pricingGenerator();
  feautersBlock();
  showAboutVeronica();
  showFeedback();

  if (window.innerWidth > 991) {
    mouseHandler();
  }

  firstAnimations();
  secondAnimations();
  stepBlocks();
  const swiper = new Swiper(".success .swiper", {
    slidesPerView: 1,
    spaceBetween: "5%",
    updateOnWindowResize: true,
    observer: true,
    observeParents: true,
    speed: 500,
    // freeMode: {
    //   enabled: true,
    //   momentumBounce: false
    // },
    mousewheel: {
      releaseOnEdges: true,
      noMousewheelClass: "no-swipe",
    //   sensitivity: 2,
    //   thresholdDelta: 2,
    //   thresholdTime: 1000,
    }, 
  });
  swiper.on("slideChange", function () {
    let slideCuttent = document.querySelector(".success__numbers span");
    slideCuttent.innerHTML = `${swiper.realIndex + 1}`;
    console.log(swiper.realIndex);
  });

  const toTop = document.querySelector(".footer__to-top");

  toTop.addEventListener("click", () => {
    gsap.to(window, { duration: 3, ease: "power2.out", scrollTo: 0 });
  });

  const footerLogo = document.querySelector(".footer__logo");

  footerLogo.addEventListener("click", () => {
    gsap.to(window, { duration: 3, ease: "power2.out", scrollTo: 0 });
  });

  const toFounder = document.querySelector(".header__links a:first-child");
  
  toFounder.addEventListener("click", () => {
    gsap.to(window, { duration: 3, ease: "power2.out", scrollTo: ".founder" });
  });

  const toFounderFromFooter = document.querySelector(".footer__nav li:nth-child(5)");
  toFounderFromFooter.addEventListener("click", () => {
    gsap.to(window, { duration: 3, ease: "power2.out", scrollTo: ".founder" });
  });

  const toCases = document.querySelector(".header__links a:nth-child(2)");
  toCases.addEventListener("click", () => {
    gsap.to(window, { duration: 3, ease: "power2.out", scrollTo: ".success" });
  });

  const toCasesFromFooter = document.querySelector(".footer__nav li:nth-child(6)");
  toCasesFromFooter.addEventListener("click", () => {
    gsap.to(window, { duration: 3, ease: "power2.out", scrollTo: ".success" });
  });

  const toPricing = document.querySelector(".header__links a:nth-child(3)");
  
  toPricing.addEventListener("click", () => {
    gsap.to(window, { duration: 3, ease: "power2.out", scrollTo: ".pricing" });
  });
 
  const toPricingFromFooter = document.querySelector(".footer__nav li:nth-child(7)");
  toPricingFromFooter.addEventListener("click", () => {
    gsap.to(window, { duration: 3, ease: "power2.out", scrollTo: ".pricing" });
  });

  const toNumbersFromFooter = document.querySelector(".footer__nav li:nth-child(1)");
  toNumbersFromFooter.addEventListener("click", () => {
    gsap.to(window, { duration: 3, ease: "power2.out", scrollTo: ".numbers" });
  });

  const toWhoFromFooter = document.querySelector(".footer__nav li:nth-child(2)");
  toWhoFromFooter.addEventListener("click", () => {
    gsap.to(window, { duration: 3, ease: "power2.out", scrollTo: ".who" });
  });

  const toFeautersFromFooter = document.querySelector(".footer__nav li:nth-child(3)");
  toFeautersFromFooter.addEventListener("click", () => {
    gsap.to(window, { duration: 3, ease: "power2.out", scrollTo: ".feauters" });
  });

  const toApproachFromFooter = document.querySelector(".footer__nav li:nth-child(4)");
  toApproachFromFooter.addEventListener("click", () => {
    gsap.to(window, { duration: 3, ease: "power2.out", scrollTo: ".approach" });
  });

  const toContact = document.querySelector(".header__links a:nth-child(4)");
  
  toContact.addEventListener("click", () => {
    gsap.to(window, { duration: 3, ease: "power2.out", scrollTo: ".footer" });
  });
});
