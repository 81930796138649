function showAboutVeronica () {
    const contentBlock = document.querySelector('.founder__content');
    if(window.innerWidth >= 577) {
        const showBlock = document.querySelector('.founder__about');
        const closeBtn = document.querySelector('.founder__close');
        contentBlock.addEventListener('click', () => {
            showBlock.classList.add('founder__about--active');
        })
        closeBtn.addEventListener('click', () => {
            showBlock.classList.remove('founder__about--active');
        })
    }
    if(window.innerWidth <= 576) {
        function toggleContent (btnClass, containerClass) {

            const btn = document.querySelector(btnClass),
                container = document.querySelector(containerClass);
                
            btn.addEventListener('click', () => {
                container.classList.toggle('mobile--active');
                btn.classList.toggle('mobile--active');
                container.classList.contains('mobile--active') ? toggleBlock.play() : toggleBlock.reverse();
            })
            let toggleBlock = gsap.to(container, {
                height: 'auto',
                paused: true,
                autoAlpha: 1,
                duration: .5,
                ease: 'power1.out'
            })
        }
        toggleContent('.founder__content', '.mobile');
    }
}

module.exports = showAboutVeronica;