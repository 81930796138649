function swiperPricing() {
  const swiper = new Swiper(".pricing__swiper", {
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: "3%",
      },
      576: {
        slidesPerView: 2,
        spaceBetween: "20px",
        centeredSlides: false,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: "20px",
      },
    },
  });
}
export default swiperPricing;
