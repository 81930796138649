function showFeedback() {
  const openBtns = document.querySelectorAll('.open-feedback'),
        closeBtn = document.querySelector('.feedback__close'),
        feedback = document.querySelector('.feedback')

        const tlOpen = gsap.timeline({
          defaults: {
            duration: 1,
            ease: "power4.out",
          },
          paused: true,
        })

        tlOpen.fromTo(feedback, {autoAlpha: 0, display: 'none'}, {autoAlpha: 1,display: 'flex'})
            .fromTo('.feedback__inner', {y: 100, autoAlpha: 0}, {autoAlpha: 1, y: 0}, "<50%")

  openBtns.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      e.preventDefault();
      let xPos = window.scrollX;
      let yPos = window.scrollY;
      window.onscroll = () => window.scroll(xPos, yPos);
      // feedback.style.display = 'flex'
      tlOpen.play();
      
    })
  })

  closeBtn.addEventListener('click', (e) => {
    window.onscroll = '';
    tlOpen.reverse();
  })
}

export default showFeedback;