function secondAnimations() {

  gsap.to('.footer__run-text:nth-child(1)', {
    x: '-100%',
    ease: 'linear',
    duration: 12,
    repeat: -1,
  })
  gsap.to('.footer__run-text:nth-child(2)', {
    x: '0%',
    ease: 'linear',
    duration: 12,
    repeat: -1,
  })


  if (window.innerWidth > 991) {
    const tl = gsap.timeline();

    gsap.utils.toArray(".approach__item:not(:last-child)").forEach((el) => {
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
          start: "top top",
          end: "bottom top",
          pin: true,
          pinSpacing: false,
          onEnter: () => {
            ScrollTrigger.refresh();
            ScrollTrigger.update();
          },
        },
      });
    });

    gsap.to(".footer-holder", {
      scrollTrigger: {
        trigger: ".footer-holder",
        start: "top top",
        endTrigger: ".blog",
        end: "100% top",
        pin: true,
        pinSpacing: false,
        onEnter: () => {
          ScrollTrigger.refresh();
          ScrollTrigger.update();
        },
        onEnterBack: () => {
          ScrollTrigger.refresh();
          ScrollTrigger.update();
        }
      },
    });
  }
}

export default secondAnimations;
