function swiperIncluded() {
  const swiper = new Swiper(".included__swiper", {
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: "5%",
      },
      576: {
        slidesPerView: 2,
        spaceBetween: "20px",
      }
    },
  });
}
export default swiperIncluded;
