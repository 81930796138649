function mouseHandler() {
  const parents = document.querySelectorAll(".parent-follower");

  parents.forEach((parent, i) => {
    const follower = parent.querySelector(".follower");
    const follower2 = parent.querySelector(".follower-2");
    parent.addEventListener("mousemove", (event) => {
      calcOffset(follower, follower2, event);
    });
    parent.addEventListener("mouseover", (event) => {
      calcOffset(follower, follower2, event);
    });
  });

  function calcOffset(follower, follower2, event) {
    gsap.to(follower, 0.3, {
      top: event.offsetY,
      left: event.offsetX,
    });
    if (follower2) {
      gsap.to(follower2, 0.7, {
        top: event.offsetY,
        left: event.offsetX,
      });
    }
  }
}

export default mouseHandler;
