function firstAnimations() {
    const tlShowHeader = gsap.timeline({
        defaults: {
          duration: 1,
          ease: "power4.out"
        }
    });

    tlShowHeader.from(".header__logo img", { opacity: 0, stagger: 0.3})
                .from(".header__logo svg", { opacity: 0, stagger: 0.3}, "<30%")
                .from(".header__item", { opacity: 0, stagger: 0.3}, "<30%")
                .from(".header__lang", { opacity: 0, stagger: 0.3}, "<30%")

    const tlShowFirstScreen = gsap.timeline({
        defaults: {
            duration: 1,
            ease: "power4.out"
          }
    });

    tlShowFirstScreen.from(".promo__wrapper--left h1", { y: 80, opacity: 0})  
                      .from(".promo__descr", { y: 80, opacity: 0}) 
    
    gsap.to(".promo__text", {
            duration: 7,
            ease: "none",
            rotate: "+=360deg",
            repeat: -1
    });
    gsap.to(".approach__text", {
        duration: 7,
        ease: "none",
        rotate: "+=360deg",
        repeat: -1
});

    let mm = gsap.matchMedia();
    mm.add({
      isMobile: "(max-width: 576px)",
      isDesktop: "(min-width: 577px)"
    }, (context) => {
        let { isMobile, isDesktop } = context.conditions;
        const tlShowNumbers = gsap.timeline({
        scrollTrigger: {
            trigger: ".numbers__item",
            start: "top 90%",
            toggleActions: "play none none reverse",
            // scrub: true
        },
        });
        tlShowNumbers.from(".numbers__item:first-child span", {textContent: 0, duration: 0.5, snap: {textContent: 1}}, "<50%")
                        .from(".numbers__item:nth-child(2) span", {textContent: 0, duration: 0.5, snap: {textContent: 1}}, "<50%")
                        .from(".numbers__item:nth-child(3) span", {textContent: 0, duration: 0.5, snap: {textContent: 1}}, "<50%")
                        .from(".numbers__item:nth-child(4) span", {textContent: 0, duration: 0.5, snap: {textContent: 1}}, "<50%")
    
        const tlShowWho = gsap.timeline({
            scrollTrigger: {
                trigger: ".who__title",
                start: "top 90%",
                toggleActions: "play none none reverse",
                // scrub: true
                },
            });
        tlShowWho.from(".who__title", { y: 80, opacity: 0})

        const tlShowWhoContent = gsap.timeline({
            scrollTrigger: {
                trigger: ".who__cards",
                start: "top 70%",
                toggleActions: "play none none reverse",
                // scrub: true
                },
            });
        tlShowWhoContent.from(".who__cards li:first-child", {y: 80}) 
                         .from(".who__cards li:nth-child(2)", { y: 80}, "<30%") 
                         .from(".who__cards li:last-child", { y: 80}, "<30%") 
                         .from(".who__cards li:nth-child(3)", { y: 80}, "<30%") 
        
        const tlShowfeauters = gsap.timeline({
        scrollTrigger: {
            trigger: ".feauters__title",
            start: "top 90%",
            toggleActions: "play none none reverse",
            // scrub: true
            },
        });
        tlShowfeauters.from(".feauters__title  span", { y: 80, opacity: 0})

        const tlShowFeautersContent = gsap.timeline({
            scrollTrigger: {
                trigger: ".feauters__item",
                start: "top 90%",
                toggleActions: "play none none reverse",
                scrub: true
            },
        });
        tlShowFeautersContent.from(".feauters__item .feauters__subtitle", {y: 80, stagger: 0.2, opacity: 0}) 
        const tlShowAproach = gsap.timeline({
            scrollTrigger: {
                trigger: ".approach__subtitle",
                start: "top 90%",
                toggleActions: "play none none reverse",
                // scrub: true
                },
            });
        tlShowAproach.from(".approach__subtitle", { y: 80, opacity: 0})
                        .from(".approach__title", { y: 80, opacity: 0})
    
        const tlShowSuccess = gsap.timeline({
            scrollTrigger: {
                trigger: ".success__title",
                start: "top 90%",
                toggleActions: "play none none reverse",
                // scrub: true
                },
            });
        tlShowSuccess.from(".success__title", { y: 80, opacity: 0})

        const tlShowFounder = gsap.timeline({
            scrollTrigger: {
                trigger: ".founder__title",
                start: "top 90%",
                toggleActions: "play none none reverse",
                // scrub: true
                },
            });
        tlShowFounder.from(".founder__title", { y: 80, opacity: 0})


        const tlShowPricing = gsap.timeline({
            scrollTrigger: {
                trigger: ".pricing__title",
                start: "top 90%",
                toggleActions: "play none none reverse",
                // scrub: true
                },
            });
        tlShowPricing.from(".pricing__title", { y: 80, opacity: 0})

        const tlShowPricingContent = gsap.timeline({
            scrollTrigger: {
                trigger: ".pricing__list",
                start: "top 80%",
                toggleActions: "play none none reverse",
                // scrub: true
                },
            });
        tlShowPricingContent.from(".pricing__list li.pricing__item:first-child", {y: 80}) 
                         .from(".pricing__list li.pricing__item:nth-child(2)", { y: 80}, "<30%") 
                         .from(".pricing__list li.pricing__item:nth-child(3)", { y: 80}, "<30%") 

        const tlShowPricingSecondContent = gsap.timeline({
            scrollTrigger: {
                trigger: ".included",
                start: "top 80%",
                toggleActions: "play none none reverse",
                // scrub: true
            },  
        });
        tlShowPricingSecondContent.from(".included__box", {y: 80}, "<30%") 
                                  .from(".included__wrapper div.included__slide:first-child", { y: 80}, "<30%")
                                  .from(".included__wrapper div.included__slide:nth-child(2)", { y: 80}, "<30%")

        const tlShowBlog = gsap.timeline({
            scrollTrigger: {
                trigger: ".blog__title",
                start: "top 90%",
                toggleActions: "play none none reverse",
                // scrub: true
                },
            });
        tlShowBlog.from(".blog__title", { y: 80, opacity: 0})
    })

}

export default firstAnimations;