function pricingGenerator() {
  const tabs = document.querySelectorAll(".pricing__tab"),
    prices = document.querySelectorAll(".pricing__price-subs span");

  tabs.forEach((tab, i) => {
    tab.addEventListener("click", (e) => onTabClick(e.target));
  });

  onTabClick(document.querySelector(".pricing__tab.active"));

  function onTabClick(target) {
    tabs.forEach((tab) => {
      tab.classList.remove("active");
    });
    target.classList.add("active");

    const months = target.getAttribute("data-months");

    prices.forEach((item) => {
      let current = +item.textContent.slice(1);
      const price = +item.getAttribute(`data-${months}`);
      gsap.fromTo(
        item,
        {
          textContent: current,
        },
        {
          duration: 1,
          ease: "power3",
          textContent: price,
          modifiers: {
            textContent: (textContent) => `$${textContent.toFixed()}`,
          },
        }
      );

      if (months == "1-month") {
        item.style.color = "#AFB0B2";
      } else {
        item.style.color = "";
      }
    });
  }
}

export default pricingGenerator;
