function feautersBlock() {
    if (window.innerWidth <= 991) {
        const itemBlocks = document.querySelectorAll('.feauters__item');
        const descriptions = document.querySelectorAll('.feauters__descr');

        itemBlocks.forEach((item, i) => {
            let animateBox = gsap.to(descriptions[i], {
                height: 'auto',
                paused: true,
                autoAlpha: 1,
                scale: 1,
                duration: 0.5,
                ease: 'power1.out'
            })

            item.addEventListener('click', () => {
                item.classList.toggle('feauters__item--active');
                item.classList.contains('feauters__item--active') ? animateBox.play() : animateBox.reverse();
            })
        })
    }
}

export default feautersBlock;