function stepBlocks() {
    if (window.innerWidth <= 576) {
        const itemBlocks = document.querySelectorAll('.approach__item');
        const animateBlocks = document.querySelectorAll('.approach__animate');
        const videos = document.querySelectorAll('.approach__animate video');
        const descriptions = document.querySelectorAll('.approach__descr');
        itemBlocks.forEach((item, i, arr) => {
            item.addEventListener('click', () => {
                item.classList.toggle('approach__item--active')

                let animateBox = gsap.to(animateBlocks[i], {
                    height: 'auto',
                    marginBottom: '20px',
                    marginTop: '20px',
                    paused: true,
                    autoAlpha: 1,
                    duration: .5,
                    ease: 'power1.out'
                })

                let collapseBox = gsap.to(animateBlocks[i], {
                    height: 0,
                    marginBottom: 0,
                    marginTop: 0,
                    paused: true,
                    autoAlpha: 0,
                    duration: .5,
                    ease: 'power1.out'
                })

                let animateVideo = gsap.to(videos[i], {
                    height: '100%',
                    paused: true,
                    autoAlpha: 1,
                    // duration: .5,
                    ease: 'power1.out'
                })
                let collapseVideo = gsap.to(videos[i], {
                    height: '0',
                    paused: true,
                    autoAlpha: 0,
                    // duration: .5,
                    ease: 'power1.out'
                })

                let animateDescr = gsap.to(descriptions[i], {
                    height: 'auto',
                    paused: true,
                    autoAlpha: 1,
                    duration: .5,
                    ease: 'power1.out'
                })

                let collapseDescr = gsap.to(descriptions[i], {
                    height: 0,
                    paused: true,
                    autoAlpha: 0,
                    duration: .5,
                    ease: 'power1.out'
                })

                item.classList.contains('approach__item--active') ? (animateBox.play() && animateVideo.play() && animateDescr.play()) : (collapseBox.play() && collapseVideo.play() && collapseDescr.play());
            }) 
        })
    }
}

export default stepBlocks;